<template>
  <div>
    <div :key="plan.id" v-for="plan in planList">
      <vs-table :data="plan.usage"
        :no-data-text="$t('nenhum-registro-encontrado')">
          <template #header>
            <h4 class="p-2">{{ plan.name }} </h4>
          </template>
          <template slot="thead">
            <vs-th width="30%">{{ $t('plan.limits') }}</vs-th>
            <vs-th width="20%">{{ $t('plan.start') }}</vs-th>
            <vs-th width="20%">{{ $t('plan.finish') }}</vs-th>
            <vs-th width="10%">{{ $t('plan.total') }}</vs-th>
            <vs-th width="10%">{{ $t('plan.usage') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="data[i]" v-for="(tr, i) in data" :style="rowStyle(data[i])">
              <vs-td>
                {{$t(`plan.limit.${data[i].type.name}`)}}
              </vs-td>
              <vs-td>
                {{data[i].start_at_fmt}}
              </vs-td>
              <vs-td>
                {{data[i].finish_at_fmt}}
              </vs-td>
              <vs-td>
                {{data[i].total}}
              </vs-td>
              <vs-td>
                {{data[i].count}}
              </vs-td>
            </vs-tr>
          </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import PlanBillingService from '@/services/api/PlanBillingService'

export default {
  directives: {

  },
  components: {

  },
  data: () => ({
    service: null,
    planList: []
  }),
  methods: {
    loadData() {
      this.$vs.loading()
      this.service.getMySubscriptions().then(
        response => {
          this.planList = response
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    rowStyle(entry) {
      if (entry.count >= entry.total) return 'color: #ff6000'
    },
  },
  beforeMount() {
    this.service = PlanBillingService.build(this.$vs)
  },
  mounted() {
    this.loadData()
  },
}
</script>
